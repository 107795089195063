/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js"
import PageHeader from "components/PageHeader/PageHeader.js"
import Footer from "components/Footer/Footer.js"

// sections for this page/view
import Basics from "views/IndexSections/Basics.js"
import Navbars from "views/IndexSections/Navbars.js"
import Tabs from "views/IndexSections/Tabs.js"
import Pagination from "views/IndexSections/Pagination.js"
import Notifications from "views/IndexSections/Notifications.js"
import Typography from "views/IndexSections/Typography.js"
import JavaScript from "views/IndexSections/JavaScript.js"
import NucleoIcons from "views/IndexSections/NucleoIcons.js"
import Signup from "views/IndexSections/Signup.js"
import Examples from "views/IndexSections/Examples.js"
import Download from "views/IndexSections/Download.js"
import Landing from "views/examples/ProfilePage.js"
import { render } from "react-dom"
import { Launcher } from "react-beautiful-chat"
import { Widget } from "react-chat-widget"
import App from "views/IndexSections/chat.js"

class Index extends React.Component {
  constructor() {
    let messageHistory = null
    super()
    this.state = {
      messageList: messageHistory,
    }
  }
  componentDidMount() {
    document.body.classList.toggle("index-page")
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page")
  }

  _onMessageWasSent(message) {
    this.setState({
      messageList: [...this.state.messageList, message],
    })
  }

  _sendMessage(text) {
    if (text.length > 0) {
      this.setState({
        messageList: [
          ...this.state.messageList,
          {
            author: "them",
            type: "text",
            data: { text },
          },
        ],
      })
    }
  }

  render() {
    return (
      <>
        <div className="App">
          <App />
        </div>
        <IndexNavbar />
        <div className="wrapper">
          <PageHeader />
          <div className="main">
            <Landing />
            {/* <Basics />
            <Navbars />
            <Tabs />
            <Pagination />
            <Notifications />
            <Typography />
            <JavaScript />
            <NucleoIcons /> */}
            <Signup />
            {/* <Examples />
            <Download /> */}
          </div>
          <Footer />
        </div>
      </>
    )
  }
}

export default Index
