import React, { Component } from "react"
import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  addUserMessage,
} from "react-chat-widget"

import "react-chat-widget/lib/styles.css"

class App extends Component {
  componentDidMount() {
    addResponseMessage("Bienvenido!")
  }

  handleNewUserMessage = (newMessage) => {
    console.log(`Nuevo Mensaje! ${newMessage}`)
    // Now send the message throught the backend API
  }

  render() {
    return (
      <div className="App">
        <Widget
          handleNewUserMessage={this.handleNewUserMessage}
          title="Yahoo"
          subtitle="Servicio de Ventas"
        />
      </div>
    )
  }
}

export default App
